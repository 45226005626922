<template>
  <v-container>
    <v-row justify="center" class="text-center">
      <v-col cols="12" md="6">
        <v-card class="pa-4" outlined>
          <v-card-title class="headline"
            ><v-icon left>mdi mdi-thermometer</v-icon> Lämpötila</v-card-title
          >
          <v-card-text class="display-2 font-weight-bold text-red">
            {{ latestTemperature }} °C
            <v-col cols="12">
              <div class="caption">{{ latestTimestamp }}</div>
            </v-col>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card class="pa-4" outlined>
          <v-card-title class="headline"
            ><v-icon left>mdi mdi-water-percent</v-icon> Kosteus</v-card-title
          >
          <v-card-text class="display-2 font-weight-bold text-blue">
            {{ latestHumidity }} %
            <v-col cols="12">
              <div class="caption">{{ latestTimestamp }}</div>
            </v-col>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        Akun varaus
        <v-icon size="16">mdi {{ getBatteryIcon(latestBattery) }}</v-icon>
        <span class="font-weight-bold">{{ latestBattery }}%</span>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <canvas ref="chartCanvas"></canvas>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Chart } from "chart.js/auto";
import dayjs from "dayjs";

export default {
  data: () => ({
    chartInstance: null,
    latestTemperature: "--",
    latestHumidity: "--",
    latestTimestamp: "--",
    latestBattery: "--",
  }),
  props: {
    sensorData: {
      type: Array,
      required: true,
    },
  },
  watch: {
    sensorData() {
      this.updateLatestValues();
      this.renderChart();
    },
  },
  mounted() {
    this.updateLatestValues();
    this.renderChart();
  },
  methods: {
    updateLatestValues() {
      if (this.sensorData.length > 0) {
        // Sort data by timestamp to get the latest value
        let latestEntry = this.sensorData[0];

        this.latestTemperature = parseFloat(latestEntry.temperature).toFixed(1);
        this.latestHumidity = parseFloat(latestEntry.humidity).toFixed(1);
        this.latestBattery = parseFloat(latestEntry.battery).toFixed(1);
        this.latestTimestamp = dayjs(latestEntry.timestamp).format("LLLL");
      }
    },
    aggregateData(data) {
      const grouped = {};
      /*data.forEach((item) => {
        const day = dayjs(item.timestamp).format("YYYY-MM-DD HH:mm");
        let day00 = day + ":00";
        let day30 = day + ":30";
        if (!grouped[day00]) {
          grouped[day00] = { sumTemp: 0, sumHumidity: 0, count: 0 };
        }
        grouped[day00].sumTemp += item.temperature;
        grouped[day00].sumHumidity += item.humidity;
        grouped[day00].count += 1;
        grouped[day00].minute = "00";

        if (!grouped[day30]) {
          grouped[day30] = { sumTemp: 0, sumHumidity: 0, count: 0 };
        }
        grouped[day30].sumTemp += item.temperature;
        grouped[day30].sumHumidity += item.humidity;
        grouped[day30].count += 1;
        grouped[day30].minute = "30";
      });*/

      /*return Object.keys(grouped).map((day) => ({
        timestamp: `${day}:${grouped[day].minute}`,
        temperature: parseFloat(
          parseFloat(grouped[day].sumTemp / grouped[day].count).toFixed(1)
        ),
        humidity: parseFloat(
          parseFloat(grouped[day].sumHumidity / grouped[day].count).toFixed(1)
        ),
      }));*/

      return data.map((item) => ({
        timestamp: dayjs(item.timestamp).format("YYYY-MM-DD HH:mm"),
        temperature: parseFloat(parseFloat(item.temperature).toFixed(1)),
        humidity: parseFloat(parseFloat(item.humidity).toFixed(1)),
      }));
    },
    getBatteryIcon(batteryLevel) {
      if (batteryLevel >= 90) return "mdi-battery";
      if (batteryLevel >= 80) return "mdi-battery-90";
      if (batteryLevel >= 70) return "mdi-battery-80";
      if (batteryLevel >= 60) return "mdi-battery-70";
      if (batteryLevel >= 50) return "mdi-battery-60";
      if (batteryLevel >= 40) return "mdi-battery-50";
      if (batteryLevel >= 30) return "mdi-battery-40";
      if (batteryLevel >= 20) return "mdi-battery-30";
      if (batteryLevel >= 10) return "mdi-battery-20";
      return "mdi-battery-10";
    },
    renderChart() {
      if (this.chartInstance) {
        this.chartInstance.destroy();
      }
      const ctx = this.$refs.chartCanvas.getContext("2d");

      // Sort data by timestamp (ascending)
      let groupedData = this.aggregateData(this.sensorData);
      groupedData.reverse();

      const timestamps = groupedData.map((item) =>
        dayjs(item.timestamp).format("DD.MM.YYYY HH:mm")
      );
      const temperatures = groupedData.map((item) => item.temperature);
      const humidity = groupedData.map((item) => item.humidity);
      //
      //// Extract timestamps, temperatures, and humidity values
      //const timestamps = sortedData.map((item) =>
      //  dayjs(item.timestamp).format("DD.MM.YYYY HH:mm")
      //);
      //const temperatures = sortedData.map((item) => item.temperature);
      //const humidity = sortedData.map((item) => item.humidity);

      this.chartInstance = new Chart(ctx, {
        type: "line",
        data: {
          labels: timestamps,
          datasets: [
            {
              label: "Lämpötila (°C)",
              data: temperatures,
              borderColor: "rgba(255, 99, 132, 1)",
              backgroundColor: "rgba(255, 99, 132, 0.2)",
              borderWidth: 2,
              fill: true,
              tension: 0.4,
              yAxisID: "y", // Assign to primary Y-axis
            },
            {
              label: "Kosteus (%)",
              data: humidity,
              borderColor: "rgba(54, 162, 235, 1)",
              backgroundColor: "rgba(54, 162, 235, 0.2)",
              borderWidth: 2,
              fill: false,
              tension: 0.4,
              yAxisID: "y2", // Assign to secondary Y-axis
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              ticks: {
                maxTicksLimit: 20,
              },
              title: {
                display: true,
                text: "Aika",
              },
            },
            y: {
              beginAtZero: false,
              suggestedMin: 15,
              suggestedMax: 26,
              title: {
                display: true,
                text: "Lämpötila (°C)",
              },
            },
            y2: {
              beginAtZero: false,
              position: "right",
              suggestedMin: 20,
              suggestedMax: 100,
              title: {
                display: true,
                text: "Kosteus (%)",
              },
              grid: {
                drawOnChartArea: false, // Avoid overlapping with the primary Y-axis grid
              },
            },
          },
          plugins: {
            legend: {
              position: "top",
            },
            tooltip: {
              callbacks: {
                label: function (context) {
                  return `${context.dataset.label}: ${context.raw}`;
                },
              },
            },
            zoom: {
              pan: {
                enabled: true,
                mode: "x",
              },
              zoom: {
                wheel: {
                  enabled: true,
                },
                pinch: {
                  enabled: true,
                },
                mode: "x",
              },
            },
          },
        },
      });
    },
  },
};
</script>

<style scoped>
canvas {
  max-width: 100%;
  height: 400px;
}
</style>
