<template>
  <v-chip
    outlined
    pill
    :color="color"
    :text-color="textColor"
    :class="{ 'mb-2': $vuetify.breakpoint.xsOnly }"
    ><v-icon left>mdi mdi-thermometer</v-icon> {{ title }}
    <span class="text-subtitle-2 ml-2">{{ latestTemperature }}°C</span></v-chip
  >
</template>

<script>
export default {
  data: () => ({
    chartInstance: null,
    latestTemperature: "--",
  }),
  props: {
    title: String,
    sensorData: {
      type: Array,
      required: true,
    },
    threshold: {
      type: Number,
      default: 15,
    },
  },
  watch: {
    sensorData() {
      this.updateLatestValues();
    },
  },
  mounted() {
    this.updateLatestValues();
  },
  computed: {
    color() {
      return !isNaN(this.latestTemperature) &&
        this.latestTemperature <= this.threshold
        ? "error"
        : "info";
    },
    textColor() {
      return !isNaN(this.latestTemperature) &&
        this.latestTemperature <= this.threshold
        ? "error"
        : "default";
    },
  },
  methods: {
    updateLatestValues() {
      if (this.sensorData.length > 0) {
        // Sort data by timestamp to get the latest value
        let latestEntry = this.sensorData[0];

        this.latestTemperature = parseFloat(latestEntry.temperature).toFixed(1);
      }
    },
  },
};
</script>

<style scoped>
canvas {
  max-width: 100%;
  height: 400px;
}
</style>
