<template>
  <basic-page fluid title="Leirikoski">
    <v-row class="fill-height leirikoski">
      <v-col cols="12" lg="12" offset-xl="2" xl="8">
        <v-tabs v-model="tab">
          <v-tab href="#leirikoski"> Leirikoski</v-tab>
          <v-tab href="#vuorot"> Vuorot</v-tab>
          <v-tab href="#vihko"> Vihko</v-tab>
          <v-tab href="#poreallas"> Poreallas</v-tab>
        </v-tabs>

        <v-row class="mt-2">
          <v-col cols="12" md="12">
            <v-tabs-items v-model="tab" class="background-none mb-5 w-100">
              <v-tab-item value="leirikoski">
                <v-card>
                  <v-card-title
                    >Varaukset
                    <v-spacer />
                    <v-switch
                      v-model="showOldEvents"
                      class="mt-2"
                      dense
                      hide-details
                      inset
                      prepend-icon="mdi mdi-eye"
                      title="Näytä vanhat ja uudemmat varaukset"
                    >
                    </v-switch>
                  </v-card-title>
                  <v-divider />
                  <v-card-text class="d-flex justify-center">
                    <!--<iframe
                  frameborder="0"
                  height="600"
                  scrolling="no"
                  src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=Europe%2FHelsinki&mode=MONTH&title=Leirikosken%20varauskalenteri&showTz=1&showCalendars=1&src=NjAwb3NvNTFlY2ozbTc1M2FsMzA4cGliNThAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%233F51B5"
                  style="border: solid 1px #777"
                  width="800"
                ></iframe>-->
                    <v-row>
                      <v-col cols="12">
                        <div v-if="showOldEvents">
                          <strong
                            >Näytetään 1kk vanhemmat ja kaikki tulevat
                            varaukset</strong
                          >
                        </div>
                        <div v-else>
                          <strong
                            >Näytetään tämä viikko {{ nowWeek }} ja tulevat
                            kaksi viikkoa.
                          </strong>
                          ({{ weeksForEvents.join(", ") }})
                        </div>
                        <v-row>
                          <v-col cols="12">
                            <v-progress-linear
                              indeterminate
                              v-if="loadingEvents"
                            ></v-progress-linear>
                            <v-list>
                              <v-list-item
                                v-if="
                                  !loadingEvents && filteredEvents.length === 0
                                "
                              >
                                <v-list-item-content>
                                  <v-list-item-subtitle>
                                    <strong>Ei varauksia</strong>
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item
                                v-for="event in filteredEvents"
                                :key="event.id"
                                :class="{ 'blue lighten-5': event._isThisWeek }"
                              >
                                <v-list-item-content>
                                  <v-list-item-title
                                    ><strong>{{
                                      $formatWeekDayDate(event.date)
                                    }}</strong></v-list-item-title
                                  >
                                  <v-list-item-subtitle style="text-wrap: auto">
                                    {{
                                      [
                                        [
                                          event.firstname?.trim(),
                                          event.lastname?.trim(),
                                        ]
                                          .filter(Boolean)
                                          .join(" "),
                                        event.company?.trim(),
                                        event.phone?.trim(),
                                      ]
                                        .filter(Boolean)
                                        .join(", ")
                                    }}
                                  </v-list-item-subtitle>
                                  <v-list-item-subtitle
                                    :class="{
                                      'success--text': event.amount === 0,
                                      'error--text': event.amount > 0,
                                    }"
                                  >
                                    {{
                                      parseFloat(event.amount / 100)
                                        .toFixed(2)
                                        .replace(".", ",")
                                    }}
                                    €
                                  </v-list-item-subtitle>

                                  <v-list-item-subtitle class="mt-2">
                                    <strong>Varatut tuotteet:</strong>
                                    <ul>
                                      <li
                                        v-for="product in event?.products"
                                        :key="product.id"
                                      >
                                        {{ product.title }}
                                      </li>
                                    </ul>
                                  </v-list-item-subtitle>
                                  <v-divider />
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card class="mt-5">
                  <v-card-title
                    >Kiinteistöpäivystäjä
                    <v-spacer />
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-simple-table>
                      <thead>
                        <tr>
                          <th>Viikko</th>
                          <th>Kiinteistöpäivystäjä vuorossa</th>
                          <th>Varapäivystäjä</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(
                            talonhenkilo, index
                          ) in talonhenkilotCurrentNext"
                          :key="index"
                        >
                          <td :class="[0 === index && 'blue lighten-5']">
                            {{ talonhenkilo[0] }}
                          </td>
                          <td :class="[0 === index && 'blue lighten-5']">
                            {{ talonhenkilo[1] }}
                          </td>
                          <td :class="[0 === index && 'blue lighten-5']">
                            {{ talonhenkilo[2] }}
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-card-text>
                  <v-divider />
                </v-card>
                <v-card class="mt-5">
                  <v-card-text>
                    <v-row no-gutters justify="center">
                      <div class="mr-2">
                        <temperature-mini-chip
                          :sensor-data="sensorData1"
                          title="Koulutusrakennus"
                        />
                      </div>
                      <div class="mr-2">
                        <temperature-mini-chip
                          :sensor-data="sensorData2"
                          title="Saunarakennus"
                        />
                      </div>
                      <div class="mr-2">
                        <temperature-mini-chip
                          :sensor-data="sensorData3"
                          title="Varasto"
                          :threshold="10"
                        />
                      </div>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card class="mt-5" v-if="showTemperature">
                  <v-card-title class="d-flex justify-space-between"
                    >Koulutusrakennus
                    <div>
                      <v-btn
                        icon
                        @click="reloadSensorData()"
                        :loading="loadingSensorData"
                        ><v-icon>mdi mdi-refresh</v-icon></v-btn
                      >
                    </div></v-card-title
                  >
                  <v-divider />
                  <v-card-text>
                    <temperature-humidity-chart :sensorData="sensorData1" />
                  </v-card-text>
                </v-card>
                <v-card class="mt-5" v-if="showTemperature">
                  <v-card-title>Saunarakennus</v-card-title>
                  <v-divider />
                  <v-card-text>
                    <temperature-humidity-chart :sensorData="sensorData2" />
                  </v-card-text>
                </v-card>
                <v-card class="mt-5" v-if="showTemperature">
                  <v-card-title>Varasto</v-card-title>
                  <v-divider />
                  <v-card-text>
                    <temperature-humidity-chart :sensorData="sensorData3" />
                  </v-card-text>
                </v-card>
                <v-card class="mt-5">
                  <v-card-title
                    >Tehtävät
                    <v-spacer />
                    <v-switch
                      v-model="showOldTehtavat"
                      class="mr-2 mt-0"
                      dense
                      hide-details
                      inset
                      prepend-icon="mdi mdi-eye"
                      title="Näytä vanhat tehtävät"
                    >
                    </v-switch>

                    <v-btn color="primary" rounded @click="newTehtava">
                      <v-icon>mdi mdi-plus</v-icon>
                      Uusi tehtävä
                    </v-btn>
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-progress-linear indeterminate v-if="loadingTehtavat" />
                    <v-list>
                      <v-list-item
                        v-if="tehtavat?.length === 0 && !loadingTehtavat"
                      >
                        <v-list-item-content>
                          <v-list-item-subtitle>
                            <strong>Ei tehtäviä</strong>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <template v-for="tehtava in filteredTehtavat">
                        <v-list-item :key="tehtava.id">
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ tehtava.title }}
                            </v-list-item-title>
                            <v-list-item-subtitle class="text-pre-wrap mt-2"
                              >{{ $formatDateAndHourMinute(tehtava.createdAt) }}
                              <span class="ml-1">{{
                                tehtava.createdBy?.user
                              }}</span>
                            </v-list-item-subtitle>
                            <v-list-item-subtitle class="text-pre-wrap mt-2"
                              >{{ tehtava.description }}
                            </v-list-item-subtitle>
                            <v-divider v-if="tehtava.doneComment" />

                            <v-list-item-subtitle
                              class="mt-2"
                              v-if="tehtava.done"
                              ><div>
                                <strong>Kommentti valmistumisesta</strong>
                              </div>
                              <div>
                                {{ $formatDateAndHourMinute(tehtava.doneAt) }}
                                <span class="ml-1">{{
                                  tehtava.doneBy?.user
                                }}</span>
                                <v-btn
                                  icon
                                  x-small
                                  class="ml-2"
                                  v-if="
                                    tehtava.doneBy?.sub === $userDBContext.sub
                                  "
                                  @click="doneTehtava(tehtava)"
                                  ><v-icon>mdi mdi-pencil</v-icon></v-btn
                                >
                              </div>
                            </v-list-item-subtitle>
                            <v-list-item-subtitle class="text-pre-wrap mt-2"
                              >{{ tehtava.doneComment }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle class="text-pre-wrap mt-2">
                              <v-chip v-if="tehtava.done" color="success" small
                                >Valmis</v-chip
                              >
                              <v-chip
                                v-else-if="
                                  tehtava.assignedTo &&
                                  tehtava.assignedTo.length > 0
                                "
                                color="warning"
                                small
                                :title="`Vastuuhenkilö määritetty: ${$formatDateAndHourMinute(
                                  tehtava.assignedAt
                                )}`"
                                >Vastuuhenkilö määritetty:
                                {{
                                  tehtava.assignedTo
                                    .map((u) => u.user)
                                    .join(", ")
                                }}</v-chip
                              >
                              <v-chip v-else color="error" small
                                >Ei aloitettu</v-chip
                              >
                              <v-btn
                                v-if="
                                  !tehtava.assignedTo ||
                                  tehtava.assignedTo?.length === 0
                                "
                                x-small
                                text
                                class="ml-2"
                                @click="assignTehtava(tehtava)"
                                :disabled="loadingTehtavaAssign"
                              >
                                <v-icon>mdi mdi-account-check-outline</v-icon>
                                Otan vastuulleni
                              </v-btn>
                              <v-btn
                                v-if="
                                  tehtava.assignedTo?.length &&
                                  !tehtava.done &&
                                  tehtava.assignedTo?.some(
                                    (u) => u.sub === $userDBContext.sub
                                  )
                                "
                                x-small
                                text
                                class="ml-2"
                                @click="doneTehtava(tehtava)"
                                :disabled="loadingTehtavaDone"
                              >
                                <v-icon>mdi mdi-check</v-icon>
                                Merkkaa valmiiksi
                              </v-btn>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-btn
                              icon
                              @click="editTehtava(tehtava)"
                              :disabled="
                                tehtava.createdBy?.sub !== $userDBContext.sub
                              "
                            >
                              <v-icon>mdi mdi-pencil</v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                        <v-divider
                          :key="`${tehtava.id}_divider`"
                          class="mt-2 mb-2"
                        />
                      </template>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item value="vuorot">
                <v-card>
                  <v-card-title
                    >Kiinteistöpäivystäjän tehtävät
                    <v-spacer />
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <ol>
                      <li>Nurmikon leikkaus 1 krt / vko</li>
                      <li>
                        Auraus ja lumenluonti. Yli 10cm lunta ilmoitus
                        isännöitsijälle
                      </li>
                      <li>Hiekoitus</li>
                      <li>Vuokralaisten yhteyshenkilö ongelmatilanteissa</li>
                      <li>Ovien avaaminen / hälyttimet</li>
                      <li>
                        Siisteyden tarkastus viikottain sekä vuokralaisten
                        jälkeen
                      </li>
                      <li>
                        Porealtaan täyttö / esilämmitys tarvittaessa+ph mittaus.
                        Merkinnät APPiin Poreallas-välilehdellä!
                      </li>
                      <li>Takan lämmitys</li>
                      <li>
                        Lisää merkinnät tekemistäsi tehtävistä
                        Vihko-välilehdellä APPiin!
                      </li>
                    </ol>
                  </v-card-text>
                  <v-card-title> Huomioitavaa</v-card-title>
                  <v-card-text>
                    <ol>
                      <li>
                        Kiinteistöpäivystäjän käytössä on yhdistyksen puhelin.
                      </li>
                      <li>
                        Puhelin tulee olla aina tavoitettavissa. Tarvittaessa
                        soitonsiirto omaan numeroon. Numerosta tulee olla
                        tavoitettavissa aina kiinteistöpäivystäjä,
                        varapäivystäjä tai sijainen.
                      </li>
                      <li>
                        Kiinteistöpäivystäjän vuoro kestää maanantaista
                        maanantaihin, vaihto hälytysosaston harjoituksissa.
                      </li>
                      <li>
                        Vuoroja voi vaihtaa keskenään, tieto isännöitsijälle.
                      </li>
                      <li>
                        Ongelmatilanteessa vuokralainen soittaa aina ensin
                        kiinteistöpäivystäjälle. Mikäli kiinteistöpäivystäjä ei
                        kykene selvittämään ongelmaa, soittaa hän
                        isännöitsijälle.
                      </li>
                      <li>
                        Kiinteistöpäivystäjän puhelimen kalenterista selviää
                        Leirikosken vuokraukset.
                      </li>
                      <li>
                        Kiinteistöpäivystäjän tehtävänä on organisoida
                        viikottaiset toimet, mikäli hän ei itse niitä kykene
                        hoitamaan. Ensisijaisesti työt tekevät talonmies tai
                        vara, tarvittaessa myös muut henkilöt.
                      </li>
                      <li>
                        Kiinteistöpäivystäjän tulee tiedottaa ongelmatilanteista
                        ja muista puutteista isännöitsijää.
                      </li>
                      <li>
                        Tunnit merkitään asemalla olevaan erilliseen
                        kiinteistöpäivystäjän vihkoon.
                      </li>
                    </ol>
                  </v-card-text>
                  <v-card-subtitle>
                    Kiinteistöpäivystäjän puhelinnumero:
                    <v-icon>mdi mdi-phone</v-icon>
                    <a href="tel:+358449880968">+358449880968</a>
                  </v-card-subtitle>
                </v-card>
                <v-card class="mt-5">
                  <v-card-title
                    >Kiinteistöpäivystäjän vuorot
                    <v-spacer />
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-simple-table>
                      <thead>
                        <tr>
                          <th>Viikko</th>
                          <th>Kiinteistöpäivystäjä vuorossa</th>
                          <th>Varapäivystäjä</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(talonhenkilo, index) in talonhenkilot"
                          :key="index"
                        >
                          <td
                            :class="[
                              talonhenkiloCurrentIndex === index &&
                                'blue lighten-5',
                            ]"
                          >
                            {{ talonhenkilo[0] }}
                          </td>
                          <td
                            :class="[
                              talonhenkiloCurrentIndex === index &&
                                'blue lighten-5',
                            ]"
                          >
                            {{ talonhenkilo[1] }}
                          </td>
                          <td
                            :class="[
                              talonhenkiloCurrentIndex === index &&
                                'blue lighten-5',
                            ]"
                          >
                            {{ talonhenkilo[2] }}
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-card-text>
                  <v-divider />
                </v-card>
              </v-tab-item>

              <v-tab-item value="vihko">
                <v-card>
                  <v-card-title>
                    <v-icon>mdi mdi-notebook-outline</v-icon>
                    Kiinteistöpäivystäjän vihko
                    <v-spacer />
                    <div>
                      <v-btn color="primary" rounded @click="newVihko">
                        <v-icon>mdi mdi-plus</v-icon>
                        Uusi merkintä
                      </v-btn>
                    </div>
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-data-table
                      :headers="vihkoDataHeaders"
                      :items="filteredVihkoDatat"
                      :loading="loadingVihkoData"
                    >
                      <template v-slot:item.name="{ item }">
                        <span v-if="item.createdBy">
                          {{ item.createdBy.user }}
                        </span>
                      </template>
                      <template v-slot:item.viikko="{ item }">
                        {{ $formatWeek(item.viikko) }}
                      </template>
                      <template v-slot:item.tarkastus="{ item }">
                        {{
                          item.tarkastus.map((t) => $formatDate(t)).join("\n")
                        }}
                      </template>
                      <template v-slot:item.ovienAvaus="{ item }">
                        {{
                          item.ovienAvaus.map((t) => $formatDate(t)).join("\n")
                        }}
                      </template>
                      <template v-slot:item.tarkastusAsiakas="{ item }">
                        {{
                          item.tarkastusAsiakas
                            .map((t) => $formatDate(t))
                            .join("\n")
                        }}
                      </template>
                      <template v-slot:item.poreallas="{ item }">
                        {{
                          item.poreallas.map((t) => $formatDate(t)).join("\n")
                        }}
                      </template>
                      <template v-slot:item.lumet="{ item }">
                        {{ item.lumet.map((t) => $formatDate(t)).join("\n") }}
                      </template>
                      <template v-slot:item.nurmikko="{ item }">
                        {{
                          item.nurmikko.map((t) => $formatDate(t)).join("\n")
                        }}
                      </template>
                      <template v-slot:item.takka="{ item }">
                        {{ item.takka.map((t) => $formatDate(t)).join("\n") }}
                      </template>
                      <template v-slot:item.tunnit="{ item }">
                        {{ item.tunnit.reduce((acc, cur) => acc + cur, 0) }}
                      </template>
                      <template v-slot:item.huomiot="{ item }">
                        <span class="text-pre-wrap"
                          >{{ item.huomiot.join("\n") }}
                        </span>
                      </template>
                      <template v-slot:item.actions="{ item }">
                        <v-btn
                          v-if="item.createdBy.sub === $userDBContext.sub"
                          icon
                          @click="selectVihkoDataForEdit(item)"
                        >
                          <v-icon>mdi mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
                <v-card class="mt-5" v-if="$isSuperAdmin">
                  <v-card-title> Tuntiraportti</v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="5">
                        <v-text-field
                          v-model.number="hourReport.year"
                          type="number"
                          label="Vuosi"
                          outlined
                          dense
                        >
                          <template v-slot:append-outer>
                            <v-btn
                              color="primary"
                              @click="getHourReport"
                              class="mt-n2"
                              :loading="hourReport.loadingHourReport"
                            >
                              Hae
                            </v-btn>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item value="poreallas">
                <v-card>
                  <v-card-title>
                    <v-icon>mdi mdi-pool-thermometer</v-icon>
                    Porealtaan huolto
                    <v-spacer />
                    <div>
                      <v-btn color="primary" rounded @click="newAllasData">
                        <v-icon>mdi mdi-plus</v-icon>
                        Uusi merkintä
                      </v-btn>
                    </div>
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-data-table
                      :headers="allasDataHeaders"
                      :items="allasDatat"
                      :loading="loadingAllasData"
                    >
                      <template v-slot:item.viikko="{ item }">
                        {{ $formatWeek(item.viikko) }}
                      </template>
                      <template v-slot:item.klooritabletti="{ item }">
                        {{ $formatDate(item.klooritabletti) }}
                      </template>
                      <template v-slot:item.suodatinpesu="{ item }">
                        {{ $formatDate(item.suodatinpesu) }}
                      </template>
                      <template v-slot:item.suodatinvaihto="{ item }">
                        {{ $formatDate(item.suodatinvaihto) }}
                      </template>
                      <template v-slot:item.vedenvaihto="{ item }">
                        {{ $formatDate(item.vedenvaihto) }}
                      </template>
                      <template v-slot:item.ph="{ item }">
                        <span
                          v-if="item.ph > 7.6 || item.ph < 7.2"
                          class="error--text"
                          >{{ item.ph }}</span
                        >
                        <span v-else class="success--text">{{ item.ph }}</span>
                      </template>
                      <template v-slot:item.kloori="{ item }">
                        <span
                          v-if="item.kloori > 1.0 || item.kloori < 0.6"
                          class="error--text"
                          >{{ item.kloori }}</span
                        >
                        <span v-else class="success--text">{{
                          item.kloori
                        }}</span>
                      </template>
                      <template v-slot:item.name="{ item }">
                        <span v-if="item.createdBy">
                          {{ item.createdBy.user }}
                        </span>
                      </template>
                      <template v-slot:item.actions="{ item }">
                        <v-btn
                          v-if="item.createdBy.sub === $user.uid"
                          icon
                          @click="editAllasData(item)"
                        >
                          <v-icon>mdi mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-col>

          <v-dialog
            v-model="editModal"
            :fullscreen="$vuetify.breakpoint.smAndDown"
            max-width="600px"
            persistent
          >
            <v-card>
              <v-card-title v-if="allasData"
                >{{ allasData.id ? "Muokkaa" : "Uusi" }} porealtaan
                huoltomerkintä{{ allasData.id ? "ä" : "" }}
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-container v-if="allasData">
                  <v-row>
                    <v-col cols="12" md="12" sm="12">
                      <modal-picker
                        v-model="allasData.viikko"
                        :allowed-dates="allowedDates"
                        label="Viikko"
                        type="week"
                      >
                        <template v-slot:append="">
                          <v-btn
                            :ripple="false"
                            class="mt-n1"
                            depressed
                            plain
                            text
                            @click="allasData.viikko = todayDate"
                            >Tänään
                          </v-btn>
                        </template>
                      </modal-picker>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                      <modal-picker
                        v-model="allasData.klooritabletti"
                        :allowed-dates="allowedDates"
                        label="Klooritabletti"
                      >
                        <template v-slot:append="">
                          <v-btn
                            :ripple="false"
                            class="mt-n1"
                            depressed
                            plain
                            text
                            @click="allasData.klooritabletti = todayDate"
                            >Tänään
                          </v-btn>
                        </template>
                      </modal-picker>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                      <modal-picker
                        v-model="allasData.suodatinpesu"
                        :allowed-dates="allowedDates"
                        label="Suodatinpesu"
                      >
                        <template v-slot:append="">
                          <v-btn
                            :ripple="false"
                            class="mt-n1"
                            depressed
                            plain
                            text
                            @click="allasData.suodatinpesu = todayDate"
                            >Tänään
                          </v-btn>
                        </template>
                      </modal-picker>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                      <modal-picker
                        v-model="allasData.suodatinvaihto"
                        :allowed-dates="allowedDates"
                        label="Suodatinvaihto"
                      >
                        <template v-slot:append="">
                          <v-btn
                            :ripple="false"
                            class="mt-n1"
                            depressed
                            plain
                            text
                            @click="allasData.suodatinvaihto = todayDate"
                            >Tänään
                          </v-btn>
                        </template>
                      </modal-picker>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                      <modal-picker
                        v-model="allasData.vedenvaihto"
                        :allowed-dates="allowedDates"
                        label="Vedenvaihto"
                      >
                        <template v-slot:append="">
                          <v-btn
                            :ripple="false"
                            class="mt-n1"
                            depressed
                            plain
                            text
                            @click="allasData.vedenvaihto = todayDate"
                            >Tänään
                          </v-btn>
                        </template>
                      </modal-picker>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                      <v-text-field
                        v-model="allasData.ph"
                        hide-details
                        label="pH"
                        outlined
                        type="number"
                      />
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                      <v-text-field
                        v-model="allasData.kloori"
                        hide-details
                        label="Kloori"
                        outlined
                        type="number"
                      />
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                      <v-textarea
                        v-model="allasData.huomiot"
                        hide-details
                        label="Huomiot"
                        auto-grow
                        outlined
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-divider />
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="error" text @click="editModal = false">
                  Sulje
                </v-btn>
                <v-btn
                  :loading="loadingSaveAllasData"
                  color="primary"
                  text
                  @click="saveAllasData"
                >
                  Tallenna
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Vihko edit modal -->

          <v-dialog
            v-model="vihkoEditModal"
            :fullscreen="$vuetify.breakpoint.smAndDown"
            max-width="600px"
            persistent
          >
            <v-card>
              <v-card-title v-if="vihkoData"
                >{{ vihkoData.id ? "Muokkaa" : "Uusi" }} kiinteistöpäivystäjän
                vihon merkintä{{ vihkoData.id ? "ä" : "" }}
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-container v-if="vihkoData">
                  <v-row>
                    <!--<v-col cols="12">
                      <v-alert color="info" outlined
                        >Huom! Tee vain yksi merkintä per viikko!</v-alert
                      >
                    </v-col>-->
                    <v-col cols="12" md="12" sm="12">
                      <modal-picker
                        v-model="vihkoData.viikko"
                        :allowed-dates="allowedDates"
                        label="Viikko"
                        type="week"
                        :show-clear="false"
                      >
                        <template v-slot:append="">
                          <v-btn
                            :ripple="false"
                            class="mt-n1"
                            depressed
                            plain
                            text
                            @click="vihkoData.viikko = todayDate"
                            >Tänään
                          </v-btn>
                        </template>
                      </modal-picker>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                      <modal-picker
                        v-model="vihkoData.tarkastus"
                        :allowed-dates="allowedDates"
                        label="Alueen siisteyden tarkastus"
                        hint="Olet tarkastanut alueen yleisen siisteyden (piha-alue, koulutusrakennus, saunarakennus, kota) ja tehnyt tarvittavat toimenpiteet."
                        persistent-hint
                        :hide-details="false"
                      >
                        <template v-slot:append="">
                          <v-btn
                            :ripple="false"
                            class="mt-n1"
                            depressed
                            plain
                            text
                            @click="vihkoData.tarkastus = todayDate"
                            >Tänään
                          </v-btn>
                        </template>
                      </modal-picker>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                      <modal-picker
                        v-model="vihkoData.ovienAvaus"
                        :allowed-dates="allowedDates"
                        label="Ovien avaus asiakkaalle"
                        hint="Olet avannut ovet asiakkaalle sekä mahdollisesti esitellyt paikat."
                        persistent-hint
                        :hide-details="false"
                      >
                        <template v-slot:append="">
                          <v-btn
                            :ripple="false"
                            class="mt-n1"
                            depressed
                            plain
                            text
                            @click="vihkoData.ovienAvaus = todayDate"
                            >Tänään
                          </v-btn>
                        </template>
                      </modal-picker>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                      <modal-picker
                        v-model="vihkoData.tarkastusAsiakas"
                        :allowed-dates="allowedDates"
                        label="Siisteyden tarkastus asiakkaan jälkeen"
                        hint="Olet tarkastanut piha-alueen, koulutusrakennuksen, saunarakennuksen asiakkaan jälkeen."
                        persistent-hint
                        :hide-details="false"
                      >
                        <template v-slot:append="">
                          <v-btn
                            :ripple="false"
                            class="mt-n1"
                            depressed
                            plain
                            text
                            @click="vihkoData.tarkastusAsiakas = todayDate"
                            >Tänään
                          </v-btn>
                        </template>
                      </modal-picker>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                      <modal-picker
                        v-model="vihkoData.poreallas"
                        :allowed-dates="allowedDates"
                        label="Porealtaan hoito"
                        hint="Olet hoitanut porealtaan, mitannut kloorin ja pH:n, sekä laittanut klooritabletit kellujaan, sekä tarvittaessa lisännyt raekloorin. Tarvittaessa vaihtanut veden, pessyt suodattimen tai vaihtanut sen. Olet merkinnyt kaikki altaalle tehdyt toimenpiteet Poreallas-välilehdellä!"
                        persistent-hint
                        :hide-details="false"
                      >
                        <template v-slot:append="">
                          <v-btn
                            :ripple="false"
                            class="mt-n1"
                            depressed
                            plain
                            text
                            @click="vihkoData.poreallas = todayDate"
                            >Tänään
                          </v-btn>
                        </template>
                      </modal-picker>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                      <modal-picker
                        v-model="vihkoData.lumet"
                        :allowed-dates="allowedDates"
                        label="Auraus ja lumien luonti"
                        hint="Olet aurannut ja luonut lumet piha-alueelta sekä terassilta. Tarvittaessa hiekoittanut kulkuväylät. Ilmoittanut isännöitsijälle yli 10cm lumesta."
                        persistent-hint
                        :hide-details="false"
                      >
                        <template v-slot:append="">
                          <v-btn
                            :ripple="false"
                            class="mt-n1"
                            depressed
                            plain
                            text
                            @click="vihkoData.lumet = todayDate"
                            >Tänään
                          </v-btn>
                        </template>
                      </modal-picker>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                      <modal-picker
                        v-model="vihkoData.nurmikko"
                        :allowed-dates="allowedDates"
                        label="Nurmikon leikkaus"
                        hint="Olet leikannut alueen nurmikon."
                        persistent-hint
                        :hide-details="false"
                      >
                        <template v-slot:append="">
                          <v-btn
                            :ripple="false"
                            class="mt-n1"
                            depressed
                            plain
                            text
                            @click="vihkoData.nurmikko = todayDate"
                            >Tänään
                          </v-btn>
                        </template>
                      </modal-picker>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                      <modal-picker
                        v-model="vihkoData.takka"
                        :allowed-dates="allowedDates"
                        label="Takan lämmitys"
                        hint="Olet lämmittänyt takan kylmillä keleillä."
                        persistent-hint
                        :hide-details="false"
                      >
                        <template v-slot:append="">
                          <v-btn
                            :ripple="false"
                            class="mt-n1"
                            depressed
                            plain
                            text
                            @click="vihkoData.takka = todayDate"
                            >Tänään
                          </v-btn>
                        </template>
                      </modal-picker>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                      <v-text-field
                        v-model.number="vihkoData.tunnit"
                        label="Tuntimäärä"
                        outlined
                        type="number"
                        hint="Kuinka monta tuntia käytit aikaa Leirikoskella tai siihen liittyvissä tehtävissä."
                        persistent-hint
                      />
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                      <v-textarea
                        v-model="vihkoData.huomiot"
                        auto-grow
                        label="Huomiot / muut tehdyt työt"
                        outlined
                        hint="Kerro tässä mitä teit ja mitä huomasit. Jos teit jotain muuta kuin yllä mainitut tehtävät, kerro se tässä."
                        persistent-hint
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-divider />
              <v-card-actions>
                <v-btn
                  v-if="vihkoData?.id"
                  :loading="loadingDeleteVihko"
                  color="error"
                  @click="deleteVihko(vihkoData)"
                  >Poista
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn color="error" text @click="vihkoEditModal = false">
                  Sulje
                </v-btn>
                <v-btn
                  :loading="loadingSaveVihkoData"
                  color="primary"
                  text
                  @click="saveVihkoData"
                >
                  Tallenna
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Tehtävä edit modal -->

          <v-dialog
            v-model="tehtavaEditModal"
            :fullscreen="$vuetify.breakpoint.smAndDown"
            max-width="600px"
            persistent
          >
            <v-card>
              <v-card-title v-if="tehtava"
                >{{ tehtava.id ? "Muokkaa" : "Uusi" }} tehtävä{{
                  vihkoData.id ? "ä" : ""
                }}
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-container v-if="tehtava" fluid>
                  <v-row>
                    <v-col cols="12" md="12" sm="12">
                      <v-text-field
                        v-model="tehtava.title"
                        label="Otsikko"
                        outlined
                        hide-details
                      />
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                      <v-textarea
                        v-model="tehtava.description"
                        label="Kuvaus"
                        outlined
                        hide-details
                        auto-grow
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-autocomplete
                        :loading="loadingUsers"
                        v-model="tehtava.assignedTo"
                        label="Vastuuhenkilöt palokunnasta"
                        outlined
                        hide-details
                        multiple
                        :items="users"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-divider />
              <v-card-actions>
                <v-btn
                  v-if="tehtava?.id"
                  :loading="loadingDeleteTehtava"
                  color="error"
                  @click="deleteTehtava(tehtava)"
                  >Poista
                </v-btn>
                <v-spacer></v-spacer>

                <v-btn color="error" text @click="tehtavaEditModal = false">
                  Sulje
                </v-btn>
                <v-btn
                  :loading="loadingSaveTehtava"
                  color="primary"
                  text
                  @click="saveTehtava"
                >
                  Tallenna
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="tehtavaDoneModal"
            :fullscreen="$vuetify.breakpoint.smAndDown"
            max-width="600px"
            persistent
          >
            <v-card>
              <v-card-title v-if="tehtava"
                >Merkkaa tehtävä valmiiksi
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-container v-if="tehtava" fluid>
                  <v-row>
                    <v-col cols="12" md="12" sm="12">
                      <v-text-field
                        v-model="tehtava.title"
                        label="Otsikko"
                        outlined
                        hide-details
                        disabled
                      />
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                      <v-textarea
                        v-model="tehtava.description"
                        label="Kuvaus"
                        outlined
                        hide-details
                        auto-grow
                        disabled
                      />
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                      <v-textarea
                        v-model="tehtava.doneComment"
                        label="Kommentti valmistumisesta"
                        outlined
                        hide-details
                        auto-grow
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-divider />
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="error" text @click="tehtavaDoneModal = false">
                  Sulje
                </v-btn>
                <v-btn
                  :loading="loadingSaveTehtava"
                  color="primary"
                  text
                  @click="saveTehtavaDone"
                >
                  Tallenna
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog
            v-model="selectVihkoDataEditModal"
            :fullscreen="$vuetify.breakpoint.smAndDown"
            max-width="1200px"
            persistent
          >
            <v-card>
              <v-card-title v-if="selectVihkoDataEditModalData"
                >Valitse muokattava merkintä
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-container v-if="selectVihkoDataEditModalData" fluid>
                  <v-row>
                    <v-col cols="12" md="12" sm="12">
                      <v-data-table
                        :headers="vihkoDataHeaders"
                        :items="selectVihkoDataEditModalData"
                        :loading="loadingVihkoData"
                      >
                        <template v-slot:item.name="{ item }">
                          <span v-if="item.createdBy">
                            {{ item.createdBy.user }}
                          </span>
                        </template>
                        <template v-slot:item.viikko="{ item }">
                          {{ $formatWeek(item.viikko) }}
                        </template>
                        <template v-slot:item.tarkastus="{ item }">
                          {{ $formatDate(item.tarkastus) }}
                        </template>
                        <template v-slot:item.ovienAvaus="{ item }">
                          {{ $formatDate(item.ovienAvaus) }}
                        </template>
                        <template v-slot:item.tarkastusAsiakas="{ item }">
                          {{ $formatDate(item.tarkastusAsiakas) }}
                        </template>
                        <template v-slot:item.poreallas="{ item }">
                          {{ $formatDate(item.poreallas) }}
                        </template>
                        <template v-slot:item.lumet="{ item }">
                          {{ $formatDate(item.lumet) }}
                        </template>
                        <template v-slot:item.nurmikko="{ item }">
                          {{ $formatDate(item.nurmikko) }}
                        </template>
                        <template v-slot:item.takka="{ item }">
                          {{ $formatDate(item.takka) }}
                        </template>
                        <template v-slot:item.tunnit="{ item }">
                          {{ item.tunnit }}
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <v-btn
                            v-if="item.createdBy.sub === $userDBContext.sub"
                            icon
                            @click="editVihkoData(item)"
                          >
                            <v-icon>mdi mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-divider />
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="error"
                  text
                  @click="selectVihkoDataEditModal = false"
                >
                  Sulje
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-col>
    </v-row>
  </basic-page>
</template>

<script>
import BasicPage from "@/components/BasicPage";
import dayjs from "dayjs";
import api from "@/api";
import { orderBy } from "lodash";
import ModalPicker from "@/components/ModalPicker";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  limit,
  orderBy as orderByFirestore,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { firestore as db } from "@/plugins/firebase.app";
import * as XLSX from "xlsx";
import TemperatureHumidityChart from "@/components/TemperatureHumidityChart.vue";
import TemperatureMiniChip from "@/components/TemperatureMiniChip.vue";

let isoWeek = require("dayjs/plugin/isoWeek");
dayjs.extend(isoWeek);
export default {
  name: "Leirikoski",
  components: {
    TemperatureMiniChip,
    TemperatureHumidityChart,
    ModalPicker,
    BasicPage,
  },
  data: () => {
    return {
      loading: false,
      loadingAllasData: false,
      loadingSaveAllasData: false,
      loadingEvents: false,
      loadingVihkoData: false,
      loadingSaveVihkoData: false,
      loadingSaveTehtava: false,
      loadingTehtavat: false,
      loadingTehtavaAssign: false,
      loadingTehtavaDone: false,
      loadingDeleteTehtava: false,
      loadingDeleteVihko: false,
      loadingSensorData: false,
      users: [],
      loadingUsers: [],
      editModal: false,
      user: null,
      events: [],
      showOldEvents: false,
      showOldTehtavat: false,
      tab: "leirikoski",
      hourReport: {
        year: dayjs().year(),
        loadingHourReport: false,
      },
      allasData: {
        viikko: null,
        klooritabletti: null,
        suodatinpesu: null,
        suodatinvaihto: null,
        vedenvaihto: null,
        ph: null,
        kloori: null,
        muuta: null,
        name: null,
      },
      vihkoData: {
        id: null,
        viikko: null,
        tehtavat: [],
        tunnit: null,
        name: null,
      },
      sensorData: [],
      tehtava: null,
      tehtavat: [],
      tehtavaEditModal: false,
      tehtavaDoneModal: false,
      vihkoDatat: [],
      vihkoEditModal: false,
      selectVihkoDataEditModal: false,
      selectVihkoDataEditModalData: null,

      allasDatat: [],

      allasDataHeaders: [
        { text: "Viikko", value: "viikko" },
        { text: "Klooritabletti", value: "klooritabletti" },
        { text: "Suodatinpesu", value: "suodatinpesu" },
        { text: "Suodatinvaihto", value: "suodatinvaihto" },
        { text: "Vedenvaihto", value: "vedenvaihto" },
        { text: "pH", value: "ph" },
        { text: "Kloori (CI)", value: "kloori" },
        { text: "Huomiot", value: "huomiot" },
        { text: "Nimi", value: "name" },

        { text: "", value: "actions", width: 100 },
      ],
      vihkoDataHeaders: [
        { text: "Viikko", value: "viikko" },
        { text: "Tarkastus", value: "tarkastus" },
        { text: "Ovien avaus", value: "ovienAvaus" },
        { text: "Tarkastus asiakkaan jälkeen", value: "tarkastusAsiakas" },
        { text: "Poreallas", value: "poreallas" },
        { text: "Lumet", value: "lumet" },
        { text: "Nurmikko", value: "nurmikko" },
        { text: "Takan lämmitys", value: "takka" },
        { text: "Tuntimäärä", value: "tunnit" },
        { text: "Huomiot", value: "huomiot" },
        { text: "Nimi", value: "name" },
        { text: "", value: "actions", width: 100 },
      ],
      talonhenkilot: [
        ["51/2024", "Helin Jarkko", "Latva Aimo"],
        ["52/2024", "Jalava Juuso", "Leino Jouni"],
        ["1/2025", "Koskela Esa", "Lindgren Ville"],
        ["2/2025", "Latva Aimo", "Kankaristo Valtteri"],
        ["3/2025", "Leino Jouni", "Lähteenmäki Mika"],
        ["4/2025", "Lindgren Ville", "Pentti Pasi"],
        ["5/2025", "Kankaristo Valtteri", "Yli-Anttila Sara (Jarkko Helin)"],
        ["6/2025", "Lähteenmäki Mika", "Raikisto Mari"],
        ["7/2025", "Pentti Pasi", "Rautiainen Joni"],
        ["8/2025", "Pöyhölä Sanna", "Heiskala Jani-Petteri"],
        ["9/2025", "Raikisto Mari", "Reponen Simo"],
        ["10/2025", "Rautiainen Joni", "Salonen Tomi"],
        ["11/2025", "Reponen Matti", "Toivanen Tuula"],
        ["12/2025", "Reponen Simo", "Uusikorpi Rami"],
        ["13/2025", "Salonen Tomi", "Vehkalahti Max"],
        ["14/2025", "Toivanen Tuula", "Pihlajamäki Pasi"],
        ["15/2025", "Uusikorpi Rami", "Yli-Anttila Tomi"],
        ["16/2025", "Vehkalahti Max", "Ylinen Jarkko"],
        ["17/2025", "Pihlajamäki Pasi", "Halme Jari"],
        ["18/2025", "Yli-Anttila Tomi", "Hanhikangas Arttu"],
        ["19/2025", "Ylinen Jarkko", "Reponen Matti"],
        ["20/2025", "Halme Jari", "Heiskala Juha-Pekka"],
        ["21/2025", "Hanhikangas Arttu", "Heiskala Matti"],
        ["22/2025", "Heiskala Jani-Petteri", "Pöyhölä Sanna"],
        ["23/2025", "Heiskala Juha-Pekka", "Jalava Juuso"],
        ["24/2025", "Heiskala Matti", "Koskela Esa"],
        ["25/2025", "Yli-Anttila Sara (Jarkko Helin)", "Latva Aimo"],
        ["26/2025", "Jalava Juuso", "Leino Jouni"],
        ["27/2025", "Koskela Esa", "Lindgren Ville"],
        ["28/2025", "Latva Aimo", "Kankaristo Valtteri"],
        ["29/2025", "Leino Jouni", "Lähteenmäki Mika"],
        ["30/2025", "Lindgren Ville", "Pentti Pasi"],
        ["31/2025", "Kankaristo Valtteri", "Yli-Anttila Sara (Jarkko Helin)"],
        ["32/2025", "Lähteenmäki Mika", "Raikisto Mari"],
        ["33/2025", "Pentti Pasi", "Rautiainen Joni"],
        ["34/2025", "Pöyhölä Sanna", "Heiskala Jani-Petteri"],
        ["35/2025", "Raikisto Mari", "Reponen Simo"],
        ["36/2025", "Rautiainen Joni", "Salonen Tomi"],
        ["37/2025", "Reponen Matti", "Toivanen Tuula"],
        ["38/2025", "Reponen Simo", "Uusikorpi Rami"],
        ["39/2025", "Salonen Tomi", "Vehkalahti Max"],
        ["40/2025", "Toivanen Tuula", "Pihlajamäki Pasi"],
        ["41/2025", "Uusikorpi Rami", "Yli-Anttila Tomi"],
        ["42/2025", "Vehkalahti Max", "Ylinen Jarkko"],
        ["43/2025", "Pihlajamäki Pasi", "Halme Jari"],
        ["44/2025", "Yli-Anttila Tomi", "Hanhikangas Arttu"],
        ["45/2025", "Ylinen Jarkko", "Reponen Matti"],
        ["46/2025", "Halme Jari", "Heiskala Juha-Pekka"],
        ["47/2025", "Hanhikangas Arttu", "Heiskala Matti"],
        ["48/2025", "Heiskala Jani-Petteri", "Pöyhölä Sanna"],
        ["49/2025", "Heiskala Juha-Pekka", "Jalava Juuso"],
        ["50/2025", "Heiskala Matti", "Koskela Esa"],
        ["51/2025", "Yli-Anttila Sara (Jarkko Helin)", "Latva Aimo"],
        ["52/2025", "Jalava Juuso", "Leino Jouni"],
      ],
    };
  },
  computed: {
    showTemperature() {
      return true;
    },
    talonhenkilotCurrentNext() {
      const current = this.talonhenkilot?.[this.talonhenkiloCurrentIndex];
      const next = this.talonhenkilot?.[this.talonhenkiloNextIndex];
      return [current, next].filter(Boolean);
    },
    weeksForEvents() {
      return [
        dayjs().isoWeek() + "/" + dayjs().isoWeekYear(),
        dayjs().add(1, "week").isoWeek() +
          "/" +
          dayjs().add(1, "week").isoWeekYear(),
        dayjs().add(2, "week").isoWeek() +
          "/" +
          dayjs().add(2, "week").isoWeekYear(),
      ];
    },
    nowWeek() {
      return dayjs().isoWeek() + "/" + dayjs().isoWeekYear();
    },
    filteredEvents() {
      return orderBy(
        this.events
          .filter((e) => e.db)
          .map((e) => {
            e.amount = parseFloat(e.amount) || 0;
            e.date = dayjs(e.date, "YYYY-MM-DD").startOf("day");
            e._isOld = this.today.startOf("week").isAfter(e.date, "day");
            e._isNewerThan2Weeks = this.today
              .add(2, "week")
              .endOf("week")
              .isBefore(e.date, "day");
            e._isThisWeek = this.today.isSame(e.date, "week");
            return e;
          })
          .filter((e) => {
            if (this.showOldEvents) return true;
            return !e._isOld && !e._isNewerThan2Weeks;
          }),
        ["date"],
        ["asc"]
      );
    },
    filteredVihkoDatat() {
      const vihot = {};
      this.vihkoDatat.forEach((v) => {
        const week = dayjs(v.viikko).format("W/GGGG");
        const key = `${v.createdBy?.sub}-${week}`;
        if (!vihot[key]) {
          vihot[key] = {
            key,
            viikko: v.viikko,
            createdBy: v.createdBy,
            tarkastus: [],
            ovienAvaus: [],
            tarkastusAsiakas: [],
            poreallas: [],
            lumet: [],
            nurmikko: [],
            takka: [],
            tunnit: [],
            huomiot: [],
            vihot: [],
          };
        }
        if (v.tarkastus) vihot[key].tarkastus.push(v.tarkastus);
        if (v.ovienAvaus) vihot[key].ovienAvaus.push(v.ovienAvaus);
        if (v.tarkastusAsiakas)
          vihot[key].tarkastusAsiakas.push(v.tarkastusAsiakas);
        if (v.poreallas) vihot[key].poreallas.push(v.poreallas);
        if (v.lumet) vihot[key].lumet.push(v.lumet);
        if (v.nurmikko) vihot[key].nurmikko.push(v.nurmikko);
        if (v.takka) vihot[key].takka.push(v.takka);
        if (v.tunnit) vihot[key].tunnit.push(v.tunnit);
        if (v.huomiot) vihot[key].huomiot.push(v.huomiot);

        vihot[key].vihot.push(v);

        vihot[key].tarkastus = Array.from(new Set(vihot[key].tarkastus));
        vihot[key].ovienAvaus = Array.from(new Set(vihot[key].ovienAvaus));
        vihot[key].tarkastusAsiakas = Array.from(
          new Set(vihot[key].tarkastusAsiakas)
        );
        vihot[key].poreallas = Array.from(new Set(vihot[key].poreallas));
        vihot[key].lumet = Array.from(new Set(vihot[key].lumet));
        vihot[key].nurmikko = Array.from(new Set(vihot[key].nurmikko));
        vihot[key].takka = Array.from(new Set(vihot[key].takka));

        vihot[key].tarkastus.sort();
        vihot[key].ovienAvaus.sort();
        vihot[key].tarkastusAsiakas.sort();
        vihot[key].poreallas.sort();
        vihot[key].lumet.sort();
        vihot[key].nurmikko.sort();
        vihot[key].takka.sort();
        vihot[key].tunnit.sort();
        vihot[key].huomiot.sort();
      });
      return Object.values(vihot);
    },
    todayDate() {
      return dayjs().format("YYYY-MM-DD");
    },
    today() {
      return dayjs();
    },
    talonhenkiloCurrentIndex() {
      return this.talonhenkilot.findIndex(
        (h) => h[0] === this.today.format("W/GGGG")
      );
    },
    talonhenkiloNextIndex() {
      return this.talonhenkilot.findIndex(
        (h) => h[0] === this.today.add(1, "week").format("W/GGGG")
      );
    },
    filteredTehtavat() {
      return this.tehtavat.filter((t) => {
        if (this.showOldTehtavat) return true;
        return (
          !t.done ||
          dayjs(t.doneAt).isAfter(dayjs().add(-1, "month").startOf("month"))
        );
      });
    },
    sensorData1() {
      return (this.sensorData || []).filter(
        (s) => s.device_id === "e4b32331ede0"
      );
    },
    sensorData2() {
      return (this.sensorData || []).filter(
        (s) => s.device_id === "e4b32331e5e4"
      );
    },
    sensorData3() {
      return (this.sensorData || []).filter(
        (s) => s.device_id === "e4b3232d4fdc"
      );
    },
  },
  watch: {
    tehtava() {
      if ((this.tehtava && !this.users) || this.users.length === 0) {
        this.reloadUsers();
      }
    },
  },
  methods: {
    async reloadUsers() {
      this.loadingUsers = true;
      let response = (await api.usersList()).data;
      const users = orderBy(
        response.users,
        ["displayName", "email"],
        ["asc", "asc"]
      );
      users.forEach((u) => {
        u.text = u.displayName;
        u.value = u.email;
      });
      this.users = users;
      this.loadingUsers = false;
    },

    async deleteTehtava(tehtava) {
      if (!tehtava.id) {
        return;
      }

      if (!(await this.$deleteConfirm("Haluatko varmasti poistaa tehtävän?"))) {
        this.loadingDeleteTehtava = false;
        return;
      }
      this.loadingDeleteTehtava = true;
      try {
        await updateDoc(doc(db, "leirikoski_tehtava", tehtava.id), {
          deleted: true,
        });
        this.$toast("Tehtävä poistettu", {
          type: "success",
        });
        await this.reloadTehtavat();
        this.tehtavaEditModal = false;
      } catch (e) {
        console.error(e);
        this.$toast("Tehtävän poisto epäonnistui", {
          type: "error",
        });
      }
    },
    async doneTehtava(tehtava) {
      /*
        done: false,
        doneBy: null,
        doneAt: null,
        doneComment: null,
       */
      const _tehtava = { ...tehtava };
      _tehtava.done = true;
      _tehtava.doneBy = this.$userDBContext;
      if (!_tehtava.doneAt) {
        _tehtava.doneAt = Date.now();
      }
      if (!tehtava.doneComment) {
        _tehtava.doneComment = "";
      }
      this.tehtava = _tehtava;
      this.tehtavaDoneModal = true;
    },
    async saveTehtavaDone() {
      this.loadingSaveTehtava = true;
      try {
        await updateDoc(doc(db, "leirikoski_tehtava", this.tehtava.id), {
          done: this.tehtava.done,
          doneBy: this.tehtava.doneBy,
          doneAt: this.tehtava.doneAt,
          doneComment: this.tehtava.doneComment,
        });
        this.$toast("Tehtävä merkitty valmiiksi", {
          type: "success",
        });
        await this.reloadTehtavat();
        this.tehtava = null;
        this.tehtavaDoneModal = false;
      } catch (e) {
        this.$toast("Tehtävän tallennus epäonnistui", {
          type: "error",
        });
        console.error(e);
      }
      this.loadingSaveTehtava = false;
    },
    async assignTehtava(tehtava) {
      if (
        !(await this.$confirm(
          "Haluatko varmasti merkitä tehtävän vastuullesi?",
          {
            title: "Merkataanko tehtävä vastuullesi?",
            buttonTrueText: "Kyllä",
            buttonFalseText: "Peruuta",
          }
        ))
      ) {
        return;
      }
      this.loadingTehtavaAssign = true;
      try {
        await updateDoc(doc(db, "leirikoski_tehtava", tehtava.id), {
          assignedTo: [this.$userDBContext],
          assignedAt: Date.now(),
        });

        await this.reloadTehtavat();
        this.$toast("Tehtävä merkitty vastuullesi", {
          type: "success",
        });
      } catch (e) {
        console.error(e);
        this.$toast("Tehtävän tallennus epäonnistui", {
          type: "error",
        });
      }
      this.loadingTehtavaAssign = false;
    },
    newTehtava() {
      this.tehtava = {
        title: null,
        description: null,
        done: false,
        doneBy: null,
        doneAt: null,
        doneComment: null,
        assignedTo: [],
        assignedAt: null,
      };
      this.tehtavaEditModal = true;
    },
    editTehtava(tehtava) {
      this.tehtava = {
        ...tehtava,
        assignedTo:
          tehtava.assignedTo?.map((u) => {
            return u.email;
          }) || [],
      };
      this.tehtavaEditModal = true;
    },
    async reloadTehtavat() {
      this.loadingTehtavat = true;
      try {
        const snapshot = await getDocs(
          query(
            collection(db, "leirikoski_tehtava"),
            where("deleted", "==", false),
            orderByFirestore("createdAt", "desc"),
            limit(50)
          )
        );
        this.tehtavat = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
      } catch (e) {
        console.error(e);
        this.$toast("Tehtävien haku epäonnistui", {
          type: "error",
        });
      }
      this.loadingTehtavat = false;
    },
    async saveTehtava() {
      if (this.tehtava.title === null || this.tehtava.title === "") {
        this.$toast("Otsikko ei voi olla tyhjä", {
          type: "error",
        });
        return;
      }
      this.loadingSaveTehtava = true;

      try {
        if (this.tehtava.id) {
          // update
          if (this.$userDBContext.sub !== this.tehtava.createdBy.sub) {
            this.$toast(
              "Et voi muokata toisen käyttäjän tallentamaa tehtävää",
              {
                type: "error",
              }
            );
            this.loadingSaveVihkoData = false;
            return;
          }
          await updateDoc(doc(db, "leirikoski_tehtava", this.tehtava.id), {
            ...this.tehtava,
            createdBy: this.$userDBContext,
            assignedTo:
              this.tehtava.assignedTo
                ?.map((u) => {
                  const user = this.users.find((user) => user.email === u);
                  if (!user) return null;
                  return {
                    email: user.email,
                    sub: user.uid,
                    user: user.displayName,
                  };
                })
                .filter(Boolean) || [],
            assignedAt: this.tehtava.assignedTo?.length
              ? this.tehtava.assignedAt || Date.now()
              : null,
            //createdAt: Date.now(),
            deleted: false,
          });
        } else {
          await addDoc(collection(db, "leirikoski_tehtava"), {
            ...this.tehtava,
            createdBy: this.$userDBContext,
            createdAt: Date.now(),
            assignedTo:
              this.tehtava.assignedTo
                ?.map((u) => {
                  const user = this.users.find((user) => user.email === u);
                  if (!user) return null;
                  return {
                    email: user.email,
                    sub: user.uid,
                    user: user.displayName,
                  };
                })
                .filter(Boolean) || [],
            assignedAt: this.tehtava.assignedTo?.length
              ? this.tehtava.assignedAt || Date.now()
              : null,
            deleted: false,
          });
        }
        this.$toast("Tehtävä tallennettu", {
          type: "success",
        });
        await this.reloadTehtavat();
        this.tehtava = null;
        this.tehtavaEditModal = false;
      } catch (e) {
        this.$toast("Tehtävän tallennus epäonnistui", {
          type: "error",
        });
        console.error(e);
      }
      this.loadingSaveTehtava = false;
    },
    allowedDates(val) {
      return this.today.isSameOrAfter(val, "day");
    },

    async reloadSensorData() {
      if (!this.showTemperature) return false;
      this.loadingSensorData = true;
      try {
        const sensorData = (await api.leirikoskiSensorData()).data;
        this.sensorData = orderBy(sensorData || [], ["id"], ["desc"]);
      } catch (e) {
        console.error(e);
      }
      this.loadingSensorData = false;
    },
    reloadAll() {
      this.getEvents();
      this.reloadAllasData();
      this.reloadVihkoData();
      this.reloadTehtavat();
      this.reloadSensorData();
    },

    selectVihkoDataForEdit(item) {
      this.selectVihkoDataEditModal = true;
      this.selectVihkoDataEditModalData = item.vihot;
    },
    async deleteVihko(vihko) {
      if (!vihko?.id) {
        return;
      }

      if (
        !(await this.$deleteConfirm(
          "Haluatko varmasti poistaa vihon merkinnän?"
        ))
      ) {
        this.loadingDeleteVihko = false;
        return;
      }
      this.loadingDeleteVihko = true;
      try {
        await updateDoc(doc(db, "kiinteistopaivystaja_vihko", vihko.id), {
          deleted: true,
        });
        this.$toast("Vihon merkintä poistettu", {
          type: "success",
        });
        await this.reloadVihkoData();
        this.vihkoEditModal = false;
        this.vihkoData = null;
      } catch (e) {
        console.error(e);
        this.$toast("Vihon merkinnän poisto epäonnistui", {
          type: "error",
        });
      }
    },
    async reloadVihkoData() {
      try {
        const snapshot = await getDocs(
          query(
            collection(db, "kiinteistopaivystaja_vihko"),
            where("deleted", "==", false),
            orderByFirestore("viikko", "desc")
          )
        );
        this.vihkoDatat = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
      } catch (e) {
        console.error(e);
        this.$toast("Kiinteistöpäivystäjän vihon haku epäonnistui", {
          type: "error",
        });
      }
    },
    async getHourReport() {
      this.hourReport.loadingHourReport = true;

      try {
        const entries = this.vihkoDatat.filter((v) => {
          return dayjs(v.viikko).isoWeekYear() === this.hourReport.year;
        });
        /*return {
          Nimi: e.createdBy.user,
          Tunnit:
        };*/

        // Create new Map of users by subs
        const subs = new Map();
        entries.forEach((e) => {
          if (!subs.has(e.createdBy.sub)) {
            subs.set(e.createdBy.sub, { ...e.createdBy, tunnit: 0 });
          }
        });
        const personHours = entries.reduce((acc, e) => {
          if (!acc[e.createdBy.sub]) {
            acc[e.createdBy.sub] = 0;
          }
          acc[e.createdBy.sub] += Math.abs(e.tunnit || 0);
          return acc;
        }, {});
        Object.keys(personHours).forEach((sub) => {
          subs.get(sub).tunnit = personHours[sub];
        });
        const tuntiraportti = Array.from(subs.values()).map((e) => {
          return {
            Nimi: e.user,
            Tunnit: e.tunnit,
          };
        });
        const merkinnat = entries.map((e) => {
          return {
            Nimi: e.createdBy.user,
            Viikko: dayjs(e.viikko).format("WW/GGGG"),
            Tarkastus: e.tarkastus
              ? dayjs(e.tarkastus).format("YYYY-MM-DD")
              : null,
            OvienAvaus: e.ovienAvaus
              ? dayjs(e.ovienAvaus).format("YYYY-MM-DD")
              : null,
            TarkastusAsiakas: e.tarkastusAsiakas
              ? dayjs(e.tarkastusAsiakas).format("YYYY-MM-DD")
              : null,
            Poreallas: e.poreallas
              ? dayjs(e.poreallas).format("YYYY-MM-DD")
              : null,
            Lumet: e.lumet ? dayjs(e.lumet).format("YYYY-MM-DD") : null,
            Nurmikko: e.nurmikko
              ? dayjs(e.nurmikko).format("YYYY-MM-DD")
              : null,
            Takka: e.takka ? dayjs(e.takka).format("YYYY-MM-DD") : null,
            Tunnit: e.tunnit,
            Huomiot: e.huomiot,
          };
        });
        const worksheet1 = XLSX.utils.json_to_sheet(tuntiraportti);
        const worksheet2 = XLSX.utils.json_to_sheet(merkinnat);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet1, "Tuntiraportti");
        XLSX.utils.book_append_sheet(workbook, worksheet2, "Merkinnät");

        XLSX.writeFile(
          workbook,
          `Leirikoski_tuntiraportti_${this.hourReport.year}.xlsx`,
          { compression: true }
        );
      } catch (e) {
        console.error(e);
        this.$toast("Virhe haettaessa tuntiraporttia", { type: "error" });
      }
      this.hourReport.loadingHourReport = false;
    },

    newVihko() {
      this.vihkoData = {
        viikko: dayjs().format("YYYY-MM-DD"),
        tunnit: null,
        name: null,
        poreallas: null,
        lumet: null,
        nurmikko: null,
        ovienAvaus: null,
        tarkastus: null,
        tarkastusAsiakas: null,
        takka: null,
        huomiot: null,
      };
      this.vihkoEditModal = true;
    },
    async reloadAllasData() {
      this.loadingAllasData = true;
      let response = (await api.allasDataList()).data;

      const allasDatat = orderBy(response, ["viikko"], ["desc"]);
      this.allasDatat = allasDatat;
      this.loadingAllasData = false;
    },

    newAllasData() {
      this.allasData = {
        viikko: dayjs().format("YYYY-MM-DD"),
        klooritabletti: null,
        suodatinpesu: null,
        suodatinvaihto: null,
        vedenvaihto: null,
        ph: null,
        kloori: null,
        muuta: null,
        name: null,
      };
      this.editModal = true;
    },
    editVihkoData(item) {
      this.vihkoData = {
        ...item,
      };
      this.selectVihkoDataEditModal = false;
      this.vihkoEditModal = true;
    },
    editAllasData(item) {
      this.allasData = {
        ...item,
      };
      this.editModal = true;
    },
    async saveVihkoData() {
      this.loadingSaveVihkoData = true;
      try {
        if (this.vihkoData?.id) {
          // update
          if (this.$userDBContext.sub !== this.vihkoData.createdBy.sub) {
            this.$toast("Et voi muokata toisen käyttäjän tallentamaa vihkoa", {
              type: "error",
            });
            this.loadingSaveVihkoData = false;
            return;
          }
          await updateDoc(
            doc(db, "kiinteistopaivystaja_vihko", this.vihkoData.id),
            {
              ...this.vihkoData,
              createdBy: this.$userDBContext,
              createdAt: Date.now(),
              deleted: false,
            }
          );
        } else {
          await addDoc(collection(db, "kiinteistopaivystaja_vihko"), {
            ...this.vihkoData,
            createdBy: this.$userDBContext,
            createdAt: Date.now(),
            deleted: false,
          });
        }
        this.$toast("Kiinteistöpäivystäjän vihon tallennus onnistui", {
          type: "success",
        });
        await this.reloadVihkoData();
        this.vihkoEditModal = false;
        this.vihkoData = null;
      } catch (e) {
        this.$toast("Kiinteistöpäivystäjän vihon tallennus epäonnistui", {
          type: "error",
        });
        console.error(e);
      }

      this.loadingSaveVihkoData = false;
    },
    async saveAllasData() {
      this.loadingSaveAllasData = true;
      try {
        let response = await api.allasDataSave(this.allasData);
      } catch (e) {
        this.$toast("Altaan datan päivittäminen ei onnistunut " + e.message, {
          type: "error",
        });
        this.loadingSaveAllasData = false;
        console.error(e);
        return;
      }
      this.loadingSaveAllasData = false;
      this.editModal = false;
      this.allasData = null;
      this.$toast("Altaan data tallennettu", {
        type: "success",
      });
      await this.reloadAllasData();
    },
    async getEvents() {
      this.loadingEvents = true;
      try {
        let response = await api.leirikoskiEvents();
        this.events = response.data;
      } catch (e) {
        console.error(e);
        this.$toast("Virhe haettaessa varauksia", { type: "error" });
      }
      this.loadingEvents = false;
    },
  },
  mounted() {
    this.reloadAll();
  },
};
</script>

<style lang="scss" scoped>
.current-talonhenkilo-row {
  td {
    background: var(--v-blue-base);
  }
}

.background-none {
  background-color: transparent;
}
</style>
<style lang="scss">
.leirikoski .v-slide-group__prev.v-slide-group__prev--disabled {
  display: none !important;
}
</style>
